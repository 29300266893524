$(function () {
    initObjectAutoComplete();
    initSubscription();
    initTabs();
    initHomepageSlider();
    scrollFunction();
    initMatchheight();
    initSearch();
    initDetail();
    initBid();
    initTimers();
    initHeader();

    $('#filter-panel').hide();
    $('#filter-button').on('click', function(){
        $('#filter-panel').slideToggle();
    })
    $('body').not('#filter-button').on('click', function(evt){
       
        if($(evt.target).closest('#filter-button, #filter-panel').length){
            console.log('closer')
            return; 
        }
        $('#filter-panel').hide();
       //For descendants of menu_content being clicked, remove this check if you do not want to put constraint on descendants.
       
        
    })

    $('.valuation-close').click(function () {
        if ($('#valuation-dialog').css('bottom').replace(/[^-\d\.]/g, '') < 0) {
            $('.valuation-close').text('-');
            $('#valuation-dialog').animate({
                bottom: '0px'
            });
        } else {
            $('.valuation-close').text('+');
            $('#valuation-dialog').animate({
                bottom: '-87px'
            });
        }
    });

    $('#next-step').click(function (e) {
        e.preventDefault();

        $('.loader').css({'display': 'flex'});

        var valid = true;

        $($('input', '.step-1').get().reverse()).each(function (k, v) {
            if (! v.checkValidity()) {
                v.reportValidity();
                valid = false;
            }
        });

        if (valid === true) {
            $('.step-1').fadeOut('slow', function () {
                $('.step-2').fadeIn(function () {
                    $('.loader').hide();
                });
            });
        } else {
            $('.loader').hide();
        }
    });

    $('#valuation-form-tag').submit(function (e) {
        e.preventDefault();
        $('.loader').css({'display': 'flex'});

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        var data = {
            'postal_code': $('#postal_code').val(),
            'house_number': $('#house_number').val(),
            'addition': $('#addition').val(),
            'name': $('#name').val(),
            'phone_number': $('#phone_number').val(),
            'email': $('#email').val()
        };

        $.post('send-valuation', data).done(function (data, statusText, xhr) {
            $('.step-2').fadeOut('slow', function () {
                $('.success').fadeIn(function () {
                    $('.loader').hide();
                });
            });
        }).fail(function (xhr) {
            $('.step-2').fadeOut('slow', function () {
                $('.failed').fadeIn(function () {
                    $('.loader').hide();
                });
            });
        });
    });

    $('.redirect-to-home').click(function () {
        window.location.href = '/';
    });

    window.onscroll = function () {
        scrollFunction()
    };

    function scrollFunction() {
        if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
            $("#btn-to-top").show();
        } else {
            $("#btn-to-top").hide();
        }
    }

    function initSearch() {
        $btn_search = $('header .search-toggle');
        $search = $('#search');

        if ($search.length == 0) {
            $btn_search.hide();
        } else {

            $btn_search.click(function (e) {
                e.stopPropagation();
                e.preventDefault();

                if ($(window).width() < 768) {
                    $('header nav').slideUp(function () {
                        $search.slideToggle();
                    });
                }
            });

            const language = $('html').attr('lang');
            if (language == 'nl') {
                $('select#prijs_min option').first().text('Minimum prijs');
                $('select#prijs_max option').first().text('Maximum prijs');
                $('select#prijs_min_huur option').first().text('Minimum prijs');
                $('select#prijs_max_huur option').first().text('Maximum prijs');
            } else if (language == 'en') {
                $('select#prijs_min option').first().text('Minimum price');
                $('select#prijs_max option').first().text('Maximum price');
                $('select#prijs_min_huur option').first().text('Minimum price');
                $('select#prijs_max_huur option').first().text('Maximum price');
            }

            $select_koophuur = $('#koophuur');
            $select_koophuur.change(function () {
                if ($select_koophuur.val() == 'koop') {
                    $('#search-huurprijs select').attr('disabled', 'disabled');
                    $('#search-koopprijs select').removeAttr('disabled');
                    $('#search-huurprijs').hide();
                    $('#search-koopprijs').show();
                } else {
                    $('#search-koopprijs select').attr('disabled', 'disabled');
                    $('#search-huurprijs select').removeAttr('disabled');
                    $('#search-koopprijs').hide();
                    $('#search-huurprijs').show();
                }
            });
            $select_koophuur.change();
        }
    }


    function initMatchheight() {
        $('.matchheight').each(function () {
            $(this).find('.match').matchHeight({
                byRow: false
            });
        });
    }

    function initDetail() {
        initMaps();
        initStreetview();
    }

    function initMaps() {
        if ($('#gmap_canvas').length) {
            var latlng = new google.maps.LatLng($('#gmap_canvas').data('lat'), $('#gmap_canvas').data('lng'));

            map = new google.maps.Map(document.getElementById("gmap_canvas"), {
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                center: latlng,
                scrollwheel: false
            });

            var marker = new google.maps.Marker({
                position: latlng,
                map: map,
                // icon: '/img/icon-map.png'
            });
        }
    }

    function initStreetview() {
        if ($('#gmap_streetview').length) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                // 'address': $('#gmap_streetview').data('address')
                'location': {
                    lat: parseFloat($('#gmap_streetview').data('lat')),
                    lng: parseFloat($('#gmap_streetview').data('lng'))
                }
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var lookTo = results[0].geometry.location;
                    var panoOptions = {
                        position: lookTo,
                        panControl: false,
                        addressControl: false,
                        linksControl: false,
                        zoomControlOptions: false
                    };
                    var pano = new google.maps.StreetViewPanorama(document.getElementById('gmap_streetview'), panoOptions);
                    var service = new google.maps.StreetViewService;
                    service.getPanoramaByLocation(pano.getPosition(), 50, function (panoData) {
                        if (panoData != null) {
                            var panoCenter = panoData.location.latLng;
                            var heading = google.maps.geometry.spherical.computeHeading(panoCenter, lookTo);
                            var pov = pano.getPov();
                            pov.heading = heading;
                            pano.setPov(pov);
                        } else {
                            $('#gmap_streetview').html('Kan streetview niet genereren');
                        }
                    });
                } else {
                    $('#gmap_streetview').html('Kan streetview niet genereren');
                }
            });
        }
    }

    $('#btn-to-top').on('click', function (e) {
        e.preventDefault();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });

    $('.afspraak-button').on('click', function (e) {
        $('#bel').removeClass('active');
        $('#vraag').removeClass('active');
        $('#afspraak').addClass('active');

        $('#bel-tab').removeClass('active');
        $('#vraag-tab').removeClass('active');
        $('#afspraak-tab').addClass('active');
    });

    $('.bel-button').on('click', function (e) {
        $('#afspraak').removeClass('active');
        $('#vraag').removeClass('active');
        $('#bel').addClass('active');

        $('#afspraak-tab').removeClass('active');
        $('#vraag-tab').removeClass('active');
        $('#bel-tab').addClass('active');
    });

    $('.vraag-button').on('click', function (e) {
        $('#afspraak').removeClass('active');
        $('#bel').removeClass('active');
        $('#vraag').addClass('active');

        $('#afspraak-tab').removeClass('active');
        $('#bel-tab').removeClass('active');
        $('#vraag-tab').addClass('active');
    });

    $('.content-homepage .news-default .news-image').each(function () {
        $(this).css({
            height: $(this).outerWidth()
        });
    });

    $('.smooth_scroll_link').click(function (e) {

        $('html, body').animate({
            scrollTop: $($(this).data('id')).position().top - 50
        });
        e.preventDefault();
        return false;
    });

    $('.fancybox').fancybox();

    $(".dropdown").hover(
        function () {
            $(this).addClass('open')
        },
        function () {
            $(this).removeClass('open')
        }
    );

    $('.search_icon').click(function () {
        $(this).hide();
        $('#custom-search-input').show();
    });


    var i = 0;
    $('.box_i_sec .box_i_btn').click(function () {
        if (i == 0) {
            $('.carousel-control').css('z-index', '0');
            $('.box_i_sec').animate({
                right: 0
            }, 'fast');
        } else if (i >= 1) {
            $('.carousel-control').css('z-index', '110');
            $('.box_i_sec').animate({
                right: -270
            }, 'fast');
        }
        i++;

        if (i > 1) {
            i = 0;
        }
    });

    $('#koophuur').change(function () {
        if ($(this).val() == "huur") {
            $('.min_options_area .min_options1').hide();
            $('.min_options_area .min_options2').show();

            $('.max_options_area .max_options1').hide();
            $('.max_options_area .max_options2').show();
        } else {
            $('.min_options_area .min_options1').show();
            $('.min_options_area .min_options2').hide();

            $('.max_options_area .max_options1').show();
            $('.max_options_area .max_options2').hide();
        }
    });

    setTimeout(function () {
        $('#koophuur').trigger('change');
    }, 1200);

    /* AUTOCOMPLETER */

    var doAutoComplete = function (el) {
        el.on('keydown', function (e) {
            if (e.keyCode == 32) {
                e.stopPropagation();
            }
        });

        if (el.val() !== '') {

            $.ajax({
                url: '/nl/object-autocomplete',
                method: 'GET',
                data: {
                    q: el.val()
                }
            }).done(function (data) {
                var JSONData = JSON.parse(data);
                buildAutoCompleteDropdown(JSONData);
                setAutocompleteListner();
            });
        } else {
            $('.input_wrapper .dropdown').hide();
        }
    };

    var buildAutoCompleteDropdown = function (items) {
        if (!items.error && items.message == '') {
            $('.input_wrapper .dropdown').html('');
            for (var i = 0; i < items.data.length && i < 6; i++) {
                $('.input_wrapper .dropdown').append('<li>' + items.data[i].item + '</li>');
            }

            $('.input_wrapper .dropdown').show();
        } else {
            $('.input_wrapper .dropdown').html(items.message);
            $('.input_wrapper .dropdown').show();
        }
    };


    var setAutocompleteListner = function () {
        if ($('.input_wrapper .dropdown li').length > 0) {
            $('.input_wrapper .dropdown li').click(function () {
                if ($(this).html() != 'Geen resultaten gevonden') {
                    $('.input_wrapper.adres input').val($(this).html());
                }
                $('.input_wrapper .dropdown').hide();
            });
        }
        ;
    };

    function initObjectAutoComplete() {
        if ($('.input_wrapper [name="straatplaats"]').length > 0) {
            $('.input_wrapper [name="straatplaats"]').each(function () {
                $(this).keyup(function () {
                    delayExecute($(this));
                });
            });
        }
    };


});

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(window).width() < 767) {
            if ($(document).scrollTop() > 71) {
                $('#header').addClass('affix');
                $('body').css({
                    paddingTop: "71px"
                });
            } else {
                $('#header').removeClass('affix');
                $('body').css({
                    paddingTop: 0
                });
            }
        } else {
            if ($(document).scrollTop() > 84) {
                $('#header').addClass('affix');
                $('body').css({
                    paddingTop: "50px"
                });
            } else {
                $('#header').removeClass('affix');
                $('body').css({
                    paddingTop: 0
                });
            }
        }
    });

    // if ($(window).width() < 767) {
    //     $('#header').affix({
    //         offset: {
    //             top: 1
    //         }
    //     });
    //
    //     $('#header').on('classChange', function () {
    //         if ($('#header').hasClass('affix')) {
    //             $('header').css({
    //                 marginTop: '71px'
    //             });
    //         }
    //     });
    //
    //     clicked = 0;
    //     $('.input-button button').click(function (e) {
    //         if (clicked === 0) {
    //             e.preventDefault();
    //
    //             $('.input-field').css({
    //                 display: 'block',
    //                 position: 'absolute',
    //                 width: '300px',
    //                 left: '-110px',
    //                 top: '71px',
    //                 zIndex: '10'
    //             });
    //
    //             $('.input-field .form-control').focus();
    //
    //             clicked = 1;
    //
    //             $('.input-field .close-input').click(function () {
    //                 $('.input-field').css({
    //                     display: 'none'
    //                 });
    //
    //
    //                 clicked = 0;
    //             });
    //         }
    //     });
    //
    //     $('.zopim').each(function () {
    //         console.log($(this).find('iframe').contents().find("head").append('<style type="text/css">.meshim_widget_components_MobileChatButton {transform: scale(1) !important;}</style>'));
    //     });
    // } else {
    //     $('#header').affix({
    //         offset: {
    //             top: 85
    //         }
    //     });
    // }
});


// $(window).on("load", function () {
//     if ($(window).width() > 767) {
//         var heights = [],
//             counter = 0,
//             heighest = '';
//         $('.equalHeights').find('.equals').each(function () {
//             heights[counter] = $(this).height();
//             counter++;
//         });
//
//         for (var i = 0; i < heights.length; i++) {
//             if (heighest === '') {
//                 heighest = heights[i];
//             } else {
//                 if (heighest < heights[i]) {
//                     heighest = heights[i];
//                 }
//             }
//         }
//
//         $('.equalHeights').find('.equals').each(function () {
//             $(this).css({
//                 height: heighest + "px"
//             });
//         });
//     }
// });

$(function () {

    $(".dropdown").hover(
        function () {
            $(this).addClass('open')
        },
        function () {
            $(this).removeClass('open')
        }
    );

    //$('select').selectpicker();

    $("#owl-demo").owlCarousel({
        autoPlay: 10000, //Set AutoPlay to 3 seconds

        items: 4,
        mouseDrag: false,
        touchDrag: false,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [979, 3],
        pagination: false,
        navigation: true,
        navigationText: [
            "<i class='fa fa-angle-left'></i>",
            "<i class='fa fa-angle-right'></i>"
        ]

    });

    $("#owl-demo2").owlCarousel({
        autoPlay: 10000, //Set AutoPlay to 3 seconds

        items: 4,
        mouseDrag: false,
        touchDrag: false,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [979, 3],
        pagination: false,
        navigation: true,
        navigationText: [
            "<i class='fa fa-angle-left'></i>",
            "<i class='fa fa-angle-right'></i>"
        ]

    });

    $("#owl-demo3").owlCarousel({
        autoPlay: 20000, //Set AutoPlay to 3 seconds

        items: 1,
        mouseDrag: false,
        touchDrag: true,
        autoplayHoverPause: true,
        singleItem: true,
        pagination: true,
        navigation: false,
        navigationText: [
            "<i class='fa fa-angle-left'></i>",
            "<i class='fa fa-angle-right'></i>"
        ]

    });
});

// var recaptcha1;
// var recaptcha2;
// var recaptcha3;
// var myCallBack = function () {
//     if ($('#recaptcha1').length > 0) {
//         //Render the recaptcha1 on the element with ID "recaptcha1"
//         recaptcha1 = grecaptcha.render('recaptcha1', {
//             'sitekey': $('#recaptcha1').data('sitekey'), //Replace this with your Site key
//             'theme': 'light'
//         });
//     }
//     if ($('#recaptcha2').length > 0) {
//         //Render the recaptcha2 on the element with ID "recaptcha2"
//         recaptcha2 = grecaptcha.render('recaptcha2', {
//             'sitekey': $('#recaptcha2').data('sitekey'), //Replace this with your Site key
//             'theme': 'light'
//         });
//     }
//     if ($('#recaptcha3').length > 0) {
//         //Render the recaptcha3 on the element with ID "recaptcha3"
//         recaptcha3 = grecaptcha.render('recaptcha3', {
//             'sitekey': $('#recaptcha3').data('sitekey'), //Replace this with your Site key
//             'theme': 'light'
//         });
//     }
// };


function initHomepageSlider() {
    if ($('#carousel-example-generic').length) {
        $.getScript('js/slick.min.js', function () {
            $('#carousel-example-generic .item').not('.slick-initialized').slick({
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 10000,
                prevArrow: '<a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">' +
                    '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>' +
                    '<span class="sr-only">Previous</span>' +
                    '</a>',
                nextArrow: '<a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">' +
                    '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>' +
                    '<span class="sr-only">Next</span>' +
                    '</a>',

                slide: '.img-slide'
            });
        });
    }
}

var typingTimer;
var doneTypingInterval = 400;

function delayExecute(el) {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(
        function () {
            doAutoComplete(el);
        },
        doneTypingInterval
    );
    return true;
}


function initSubscription() {
    if ($('#frmSubscription').length > 0) {
        $('select[id^=min]').parents('.form-row').hide();
        $('select[id^=max]').parents('.form-row').hide();

        $('#frmKoop_huur').change(function () {
            if ($(this).val() == 'koop') {
                $('#minHuur').parents('.form-row').hide();
                $('#maxHuur').parents('.form-row').hide();
                $('#minKoop').parents('.form-row').show();
                $('#maxKoop').parents('.form-row').show();

                $('#minHuur').prop("disabled", true);
                $('#maxHuur').prop("disabled", true);
                $('#minKoop').prop("disabled", false);
                $('#maxKoop').prop("disabled", false);
            } else {
                $('#minKoop').parents('.form-row').hide();
                $('#maxKoop').parents('.form-row').hide();
                $('#minHuur').parents('.form-row').show();
                $('#maxHuur').parents('.form-row').show();

                $('#minHuur').prop("disabled", false);
                $('#maxHuur').prop("disabled", false);
                $('#minKoop').prop("disabled", true);
                $('#maxKoop').prop("disabled", true);
            }
        });

        $('#frmKoop_huur').change();

        $('#frmCity').after('<small style="font-size: 12px; color: #999; font-style: italic;">Houdt CTRL ingedrukt om meerdere plaatsen te selecteren.</small>')
    }
}

function initTabs() {
    var $navTabs = document.querySelectorAll('.tabs-holder nav .tabs .tab'),
        $conTabs = document.querySelectorAll('.tabs-holder .tab-contents .tab-content'),
        activeTab = document.querySelector('.tabs-holder nav .tabs .tab.active'),
        activeContent = document.querySelector('.tabs-holder .tab-contents .tab-content.active');

    var url = document.location + '',
        split = url.split('#frm'),
        form;

    if (split.length > 1) {
        form = split[1].toLowerCase();

        switch (form) {
            case 'afspraak':
                activeTab.classList.remove('active');
                activeContent.classList.remove('active');

                document.querySelector('[data-tab="#afspraak"]').classList.add('active');
                document.querySelector('.tab-content#afspraak').classList.add('active');
                break;

            case 'bel':
                activeTab.classList.remove('active');
                activeContent.classList.remove('active');

                document.querySelector('[data-tab="#bel"]').classList.add('active');
                document.querySelector('.tab-content#bel').classList.add('active');
                break;

            case 'vraag':
                activeTab.classList.remove('active');
                activeContent.classList.remove('active');

                document.querySelector('[data-tab="#vraag"]').classList.add('active');
                document.querySelector('.tab-content#vraag').classList.add('active');
                break;
        }
    }

    $navTabs.forEach(function (el) {
        el.addEventListener('click', function () {
            activeTab.classList.remove('active');
            activeContent.classList.remove('active');

            activeContent = document.querySelector(this.getAttribute('data-tab'));

            this.classList.add('active');
            activeContent.classList.add('active');

            activeTab = this;
        });
    });
}

$(document).ready(function () {
    if ($('#objects').length) {
        initViewToggle();

        if (localStorage['view-toggle'] && localStorage['view-toggle'] == 'view-map') {
            $('.view-toggle[data-toggle=view-map]').click();
        }
    }
});

function initTimers() {
    $('.timer').each(function () {
        initTimer($(this));
    });
}

function initTimer($timer) {
    var countDownDate = new Date(parseInt($timer.data('to')) * 1000);
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    var dagen = '';
    if (days > 1) {
        dagen = days + ' dagen '
    } else if (days == 0) {
        dagen = '';
    } else {
        dagen = days + ' dag ';
    }

    $timer.html('<i class="icon icon-clock-o"></i> ' + dagen + leftPad(hours, 2) + ':' + leftPad(minutes, 2) + ':' + leftPad(seconds, 2));

    if (distance < 0) {
        clearInterval(x);
        $timer.parent().text('ONLINE BIEDING GESLOTEN');
    }

    var x = setInterval(function () {
        var now = new Date().getTime();
        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        var dagen = '';
        if (days > 1) {
            dagen = days + ' dagen '
        } else if (days == 0) {
            dagen = '';
        } else {
            dagen = days + ' dag ';
        }

        const html = '<i class="icon icon-clock-o"></i> ' + dagen.toString() + leftPad(hours, 2).toString() + ':' + leftPad(minutes, 2).toString() + ':' + leftPad(seconds, 2).toString();

        $timer.html(html);

        if (distance < 0) {
            clearInterval(x);
            $timer.parent().text('ONLINE BIEDING GESLOTEN');
        }
    }, 1000);
};

function leftPad(number, targetLength) {
    var output = number + '';
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
}

function initViewToggle() {
    $('.view-map').hide();
    if ($('.view-toggle').length > 0) {
        $('.view-toggle').click(function () {

            $('.view-toggle').removeClass('active');
            $(this).addClass('active');

            $('.view').hide();
            $('.' + $(this).data('toggle')).show();

            localStorage['view-toggle'] = $(this).data('toggle');

            if ($(this).data('toggle') == 'view-map') {

                $('.sorteeropties').hide();

                var view = $(this).data('method');

                $.ajax({
                    url: window.location,
                    method: 'GET',
                    data: {
                        view: view
                    },
                    async: true
                }).done(function (data) {
                    console.log(data);

                    initMap(data);
                });
            } else {
                $('.sorteeropties').show();
            }
        });
    }
}

function initMap(data) {
    var bounds = new google.maps.LatLngBounds();
    var infowindow = new google.maps.InfoWindow();

    map = new google.maps.Map(document.getElementById("gmap_canvas"), {
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    var infowindow = new google.maps.InfoWindow();

    markers = [];
    for (i = 0; i < data.length; i++) {
        if (data[i]['latitude'] !== null && data[i]['longitude'] !== null) {
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(data[i]['latitude'], data[i]['longitude']),
                map: map,
                //icon: icon
            });

            bounds.extend(marker.position);

            google.maps.event.addListener(marker, 'click', (function (marker, i) {
                return function () {
                    content = ' <img src="' + data[i]['hoofdfoto'] + '" alt="' + data[i]['adres'] + '" style="width: 150px; margin-bottom: 10px;"><br>\n\
                            <strong>' + data[i]['adres'] + '</strong><br>\n\
                            ' + data[i]['plaats'] + '<br>\n\
                            ' + data[i]['prijs'] + '<br>\n\
                            <a href="' + data[i]['url'] + '">Bekijken</a>';

                    infowindow.setContent(content);
                    infowindow.open(map, marker);
                }
            })(marker, i));

            markers.push(marker);
        }
    }

    map.fitBounds(bounds);

    var mc = new MarkerClusterer(map, markers, {imagePath: '/img/m'});
}

function initBid() {
    if ($('#object.auction').length > 0) {

        var alertBox = $('#bidModal #alert-box');

        $('#bidModal').find('input[name=amount]').on('keyup', function (){
            alertBox.empty();

            if(clean($(this).val()) < $(this).data('bid-from')){
                alertBox.append('<div class="alert alert-danger">Het bod is te laag</div>')
            }else{
                alertBox.append('<div class="alert alert-success">Prima, het bod voldoet aan de criteria</div>');
            }
            $('#bid_formatted').html(currency(clean($(this).val())));
        });

        $('#voorbehoud_financiering_extra').hide();

        $('#voorbehoud_financiering').on('change', function (){
            if($(this).is(':checked')) {
                $('#voorbehoud_financiering_extra').show();
            }else{
                $('#voorbehoud_financiering_extra').hide();
            }
        });

        $('.open-bid-modal').on('click', function (e) {
            e.preventDefault();
            $('#bidModal').modal('show');
            var check_voorwaarden = $('#frmAuctionModal').find('input[name=voorwaarden]');

            check_voorwaarden.on('change', function () {
                check_voorwaarden.parent('label').removeClass('error');
            });

            $('#frmAuctionModal').on('submit', function (e) {
                check_voorwaarden.parent('label').removeClass('error');
                if (!check_voorwaarden.is(':checked')) {
                    check_voorwaarden.parent('label').addClass('error');
                    e.preventDefault();
                }
            });

            // var val;
            // var input = $('#frmAuction').find('input[name=tmp_amount]');
            // if (clean(input.val()) < parseInt(input.data('bid-from'))) {
            //     $('#frmAuction').before('<div class="alert alert-danger">Bod te laag</div>');
            // } else {
            //     $('#frmAuctionModal').find('input[name=amount]').val(clean(input.val()));
            //     $('#bid_formatted').html(currency(clean(input.val())));
            //
            //     if (input.data('fee-type') == 'amount') {
            //         val = clean(input.val()) + parseInt(input.data('fee-amount'));
            //     } else if (input.data('fee-type') == 'percentage') {
            //         val = ((clean(input.val()) / 100) * parseFloat(input.data('fee-amount'))) + clean(input.val());
            //     }
            //
            //
            //     $('#total_formatted').html(currency(val) + ' k.k.');
            //

            //
            // }
        });
    }
}

function initHeader() {
    if (document.querySelector('#openSearch')) {
        const openSearch = document.querySelector('#openSearch');
        const navbar = document.querySelector('.navbar-collapse');
        const searchInput = document.querySelector('#mobileSearch input');

        openSearch.addEventListener('click', function (e) {
            if (navbar.classList.contains('in')) {
                e.stopPropagation();
            } else {
                openSearch.click();
            }

            searchInput.focus();
        });
    }
}

var clean = function (amount) {
    val = parseInt(amount.replace(/\D/g, ''));
    if (isNaN(val)) return 0;
    return val;
}

var currency = function (amount) {
    decimals = (typeof decimals !== 'undefined' ? decimals : 0);
    return '&euro; ' + number_format(amount, 0, ',', '.');
}

var number_format = function (number, decimals, dec_point, thousands_sep) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
