$(document).ready(function () {
    // initStreetview();
    // initMaps();
});

function initStreetview() {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': $('#gmap_streetview').data('address')}, function (results, status) {
        var lookTo = results[0].geometry.location;
        if (status == google.maps.GeocoderStatus.OK) {
            var panoOptions = {
                position: lookTo,
                panControl: false,
                addressControl: false,
                linksControl: false,
                zoomControlOptions: false
            };
            var pano = new google.maps.StreetViewPanorama(document.getElementById('gmap_streetview'), panoOptions);
            var service = new google.maps.StreetViewService;
            service.getPanoramaByLocation(pano.getPosition(), 50, function (panoData) {
                if (panoData != null) {
                    var panoCenter = panoData.location.latLng;
                    var heading = google.maps.geometry.spherical.computeHeading(panoCenter, lookTo);
                    var pov = pano.getPov();
                    pov.heading = heading;
                    pano.setPov(pov);
                } else {
                    $('#gmap_streetview').html('Kan streetview niet genereren');
                }
            });
        } else {
            $('#gmap_streetview').html('Kan streetview niet genereren');
        }
    });
}

function initMaps() {
    var latlng = new google.maps.LatLng($('#gmap_canvas').data('lat'), $('#gmap_canvas').data('lng'));

    map = new google.maps.Map(document.getElementById("gmap_canvas"), {
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        center: latlng
    });

    var marker = new google.maps.Marker({
        position: latlng,
        map: map
    });
}